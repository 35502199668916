<template>
  <dashboard-layout>
    <div class="w-full p-5">
      <div class="flex flex-col">
        <div class="w-11/12 mx-auto">
          <div
            class="flex border-2 border-green-1002 flex px-3 py-1 rounded-lg w-min"
          >
            <router-link :to="{ name: 'my-curriculum' }">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="32"
                height="32"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                  fill="rgba(0,122,111,1)"
                />
              </svg>
            </router-link>
          </div>
          <form @submit.prevent="submit">
            <h2 class="text-2xl mb-4 font-bold">Editar Experiência</h2>

            <div class="flex flex-col">
              <label class="mb-2" for="title">
                *Título:
                <span v-if="$v.title.$error">
                  <span class="error" v-if="!$v.title.required">
                    Campo obrigatório.
                  </span>
                  <span class="error" v-else-if="!$v.title.maxLength">
                    O título deve ter no máximo
                    {{ $v.institution.$params.maxLength.max }} caracteres.
                  </span>
                </span>
              </label>
              <multiselect
                id="type"
                v-model="$v.title.$model"
                track-by="label"
                :max ="1"
                label="label"
                select-label="Selecionar"
                selected-label="Selecionado"
                deselect-label="Remover"
                tagPlaceholder="Pressione enter para criar uma experiência."
                :taggable="true"
                :searchable="true"
                :multiple="true"
                placeholder="Selecione um tipo"
                :options="sugestion"
                @tag="addTag"
              >
                <template v-slot:maxElements> Máximo de 1 opções selecionadas. Primeiro remova uma opção selecionada para selecionar outra.</template>
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.label }}
                </template>
                <span slot="noOptions">Nenhum registro encontrado.</span>
              </multiselect>
            </div>
            <div class="flex flex-col">
              <label class="mb-2" for="company">
                *Empresa:
                <span v-if="$v.company.$error">
                  <span class="error" v-if="!$v.company.required">
                    Campo obrigatório.
                  </span>
                  <span class="error" v-else-if="!$v.company.maxLength">
                    A empresa deve ter no máximo
                    {{ $v.company.$params.maxLength.max }} caracteres.
                  </span>
                </span>
              </label>
              <input
                v-model="$v.company.$model"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="company"
                type="text"
                placeholder="Empresa"
                autocomplete="off"
              />
            </div>

            <div class="flex flex-col">
              <label class="mb-2" for="contact-person">
                Pessoa de Contato:
              </label>
              <input
                v-model="contactPerson"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="contact-person"
                type="text"
                placeholder="Pessoa de Contato"
                autocomplete="off"
              />
            </div>

            <div class="flex flex-col">
              <label class="mb-2" for="contact-phone">
                Telefone do Contato:
              </label>
              <input
                v-model="contactPhone"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="contact-phone"
                type="text"
                placeholder="Telefone de Contato"
                autocomplete="off"
                v-mask="['(##) ####-####', '(##) #####-####']"
              />
            </div>

            <div class="flex-col">
              <label class="mb-2" for="started-at">
                *Data de Início:
                <span v-if="$v.startedAt.$error">
                  <span class="error" v-if="!$v.startedAt.required">
                    Campo obrigatório.
                  </span>
                </span>
              </label>
              <input
                v-model="$v.startedAt.$model"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="started-at"
                type="text"
                placeholder="Data de Início"
                v-mask="'##/##/####'"
                autocomplete="off"
              />
            </div>

            <div class="flex-col">
              <label class="mb-2" for="ended-at">Data de Fim</label>
              <input
                v-model="endedAt"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="ended-at"
                type="text"
                placeholder="Data de Fim"
                v-mask="'##/##/####'"
                autocomplete="off"
              />
            </div>

            <div class="flex justify-between">
              <button
                type="submit"
                class="bg-green-1000 text-white px-4 py-2 rounded rounded-md border border-gray-400 focus:outline-none mt-5"
              >
                Salvar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import { maxLength, required } from 'vuelidate/lib/validators';
import { mask } from 'vue-the-mask';
import axios from '@/utils/axios';
import DashboardLayout from '../../../../layouts/DashboardLayout';

export default {
  name: 'ExperienceEdit',

  title() {
    return `${process.env.VUE_APP_NAME} | Editar Experiência Profissional`;
  },

  props: {
    id: {
      required: true,
      type: Number
    }
  },

  components: {
    DashboardLayout
  },

  directives: {
    mask
  },

  data() {
    return {
      sugestion: [],
      title: [],
      company: '',
      contactPerson: '',
      contactPhone: '',
      startedAt: '',
      endedAt: ''
    };
  },

  validations: {
    title: {
      required,
      maxLength: maxLength(255)
    },
    company: {
      required,
      maxLength: maxLength(255)
    },
    startedAt: {
      required
    }
  },

  methods: {
    async fetchExperience() {
      const response = await axios.get(`/api/experiencias/${this.id}/editar`);

      this.title.push({
        id: null,
        label: response.data.data.title
      })
      this.company = response.data.data.company;
      this.contactPerson = response.data.data.contact_person;
      this.contactPhone = response.data.data.contact_phone;
      this.startedAt = response.data.data.complete_started_at;
      this.endedAt = response.data.data.complete_ended_at;
    },

    submit() {
      if (this.$v.$invalid) {
        this.$v.title.$touch();
        this.$v.company.$touch();
        this.$v.startedAt.$touch();
      } else {
        let attributes = {
          title: this.title[0].label,
          company: this.company,
          contact_person: this.contactPerson,
          contact_phone: this.contactPhone,
          started_at: this.startedAt,
          ended_at: this.endedAt
        };

        axios
          .patch(`/api/experiencias/${this.id}`, attributes)
          .then(({ data }) => {
            this.$toast.success(data.data.message);

            this.$router.push({
              name: 'my-curriculum'
            });
          })
          .catch(({ response }) => {
            Object.values(response.data.errors).forEach(error => {
              this.$toast.error(...error);
            });
          });
      }
    },

    async fetchsuggestion() {
    
    const response = await axios.get('api/palavras-chave/suggestion-experiences');

      response.data.data.forEach(suggestionfor => {
        this.sugestion.push({
          id: suggestionfor.id,
          label: suggestionfor.label
        });
      });
    },
    
    addTag (newTag) {
      const tag = {
        name: newTag,
        label: newTag
      }
      this.sugestion.push(tag)
      this.title.push(tag)
    }
  },

  created() {
    this.fetchExperience();
    this.fetchsuggestion();
  }
};
</script>

<style scoped></style>
